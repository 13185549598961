import { Alert, Grid } from "@mui/material";

import React, { useState } from "react";

import {
  CustomForm,
  CustomInput,
  CustomButton,
} from "../../../Fields/FormToolBox";
// import Captcha from "../../../Captcha";

declare global {
  interface Window {
    grecaptcha: any;
    electron: {
      language(): string;
      ipcRenderer: {
        sendMessage(channel: string, args: unknown[]): void;
      };
    };
  }
}

interface LoginFormProps {
  setOperation: React.Dispatch<React.SetStateAction<string>>;
  resetToken: string;
}
const LoginForm: React.FC<LoginFormProps> = ({
  setOperation,
  resetToken
}) => {
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [feedback, setFeedback] = useState('');
  const [feedbackType, setFeedbackType] = useState<"success" | "error" | "warning">('success');

  const submit = () => {
    if(password !== passwordConfirmation){
      setFeedbackType("error");
      setFeedback(`The password and its confirmation are not the same. Please confirm that they are the same and try again.`);
      return;
    }
    setFeedbackType("warning");
    setFeedback(`Reseting your password, please wait...`);

    const url = `/api/v1/account/auth/resetPassword/${resetToken}`;
    const params = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        password
      }),
    };

    fetch(url, params)
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      if (data.success === true) {
        setFeedbackType("success");
        setFeedback("Your account's password is now changed! You can now login using your new password.");
      }else{
        setFeedbackType("error");
        setFeedback(`Could not sent the request password request: ${data.reason}`);
      }
    });
  }

  return (
    <CustomForm>
      <Grid item xs={6} style={{ textAlign: "center" }}>
        <CustomInput
          type="password"
          name="password"
          label="New password"
          value={password}
          onChange={(evt:any) => setPassword(evt.target.value)}
          placeHolder="********"
        />
        <CustomInput
          type="password"
          name="passwordConfirmation"
          label="Please confirm your new password"
          value={passwordConfirmation}
          onChange={(evt:any) => setPasswordConfirmation(evt.target.value)}
          placeHolder="********"
        />
      </Grid>
      <Grid item xs={12}>
        {/**<Captcha onChange={(token => {
                    if (token) setCaptchaToken(token);
                })} />**/}
      </Grid>
      <Grid item xs={12} style={{ textAlign: "left" }}>
        <CustomButton
          label="Change Password"
          color="primary"
          onClick={() => submit()}
        />
        {feedback !== "" && (
          <Alert severity={feedbackType}>{feedback}</Alert>
        )}
      </Grid>
    </CustomForm>
  );
};

export default LoginForm;
