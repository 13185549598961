import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import queryString from "query-string";

//Routes
import Home from "./Pages/Home";
import FAQPage from "./Pages/FAQ";
import Authentication from "./Pages/Authentication";
import Partners from "./Pages/Partners";
import Funding from "./Pages/Funding";
import NotFound from "./Pages/NotFound";
import AppDownload from "./Pages/AppDownload";
import Applications from "./Pages/Applications";
import Forum from "./Pages/Forum";
import NavBar from "./NavBar";
import Footer from "./Footer";
import TermsAndConditions from "./Pages/TermsAndConditions";
import PrivacyPolicy from "./Pages/PrivacyPolicy";

function Router() {
  const location = useLocation();
  const pages = [
    {
      path: "https://forum.openrehab.arditi.pt/category/3/publications-articles",
      title: "Publications",
      sameWebsite: false,
    },
    {
      path: "/applications",
      title: "Applications",
      sameWebsite: true,
    },
    {
      path: "/partners",
      title: "Partners",
      sameWebsite: true,
    },
    {
      path: "/download-app",
      title: "Download ORI",
      sameWebsite: true,
    },
    /*{
          path: "/authentication",
          title: 'Register / Login'
        }*/
  ];
  const enableAppMode =
    queryString.parse(location.search)?.enableAppMode === "true" || false;

  useEffect(() => {
    console.log(queryString.parse(location.search));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AnimatePresence exitBeforeEnter>
      {!enableAppMode && <NavBar items={pages} />}
      <p></p>
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Home />} />
        <Route path="/download-app" element={<AppDownload />} />
        <Route
          path="/authentication/:operation"
          element={<Authentication enableAppMode={enableAppMode} />}
        />
        <Route
          path="/authentication/:operation/:redirect"
          element={<Authentication enableAppMode={enableAppMode} />}
        />
        <Route
          path="/authentication"
          element={<Authentication enableAppMode={enableAppMode} />}
        />
        <Route path="/partners" element={<Partners />} />
        <Route path="/funding" element={<Funding />} />
        <Route path="/forum" element={<Forum />} />
        <Route path="/faq" element={<FAQPage />} />
        <Route path="/applications" element={<Applications />} />
        <Route path="/applications/:filter" element={<Applications />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/404" element={<NotFound />} />
      </Routes>
      {!enableAppMode && <Footer />}
    </AnimatePresence>
  );
}

export default Router;
