import { createGlobalStyle } from "styled-components";

export const lightTheme = {
  body: "#FFFF",
  color: "#374957",
  border: "#E6E3E3",
  shadow: `rgba(0, 0, 0, 0.2)`,
  title: "#374957",
  circularPercentage: "#eee",
};
export const darkTheme = {
  body: "#121620",
  color: "#FFFF",
  border: "hsla(0, 100%, 100%, 0.07)",
  shadow: `hsla(0, 100%, 100%, 0.08)`,
  title: "#374957",
  circularPercentage: "hsla(0, 100%, 100%, 0.16)",
};

export const themeChangeTime = "0.3s";

export default createGlobalStyle`
    body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    }

    code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
    }
    *{
        margin: 0;
        padding: 0;
        outline:0;
        box-sizing:border-box;
    }
    #root{
        width: 100%;
        heigth: 100;
        margin: 0 auto;
    }
 `;
