import React, { useState } from "react";
import YAML from "yaml";
import { motion } from "framer-motion";

function DownloadOptions() {
  const [userAgent] = useState<"Macbook" | "Windows" | "Unknown">(
    getUserAgent()
  );

  function getUserAgent() {
    var ua = navigator.userAgent.toLowerCase();
    //if (/android/i.test(ua))
    //    return 'Android';
    if (/mac/i.test(ua)) return "Macbook";
    if (/windows/i.test(ua)) return "Windows";

    return "Unknown";
  }

  function download(os: "Windows" | "macOS-arm" | "macOS-intel") {
    let url = "/app/update/";
    let fileListURL = ``;

    switch (os) {
      case "Windows":
        url += "win32-x64";
        fileListURL += `${url}/latest.yml`;
        break;
      case "macOS-arm":
      case "macOS-intel":
        url += "darwin";
        fileListURL += `${url}/latest-mac.yml`;
        break;
      default:
        return "";
    }
    fetch(fileListURL)
      .then((r) => r.text())
      .then((t) => {
        const fileList: Array<string> = YAML.parse(t).files.map(
          (val: any) => val.url
        );
        let fileName = "";
        switch (os) {
          case "Windows":
            fileName = fileList[0];
            break;
          case "macOS-arm":
            fileName = fileList.filter((val) =>
              val.toLocaleLowerCase().includes("arm")
            )[0];
            break;
          case "macOS-intel":
            fileName = fileList.filter(
              (val) => !val.toLocaleLowerCase().includes("arm")
            )[0];
            break;
          default:
            return "";
        }

        const fileDownloadURL = `${window.location.protocol}//${window.location.host}${url}/${fileName}`;
        window.open(new URL(fileDownloadURL));
      });
  }

  return (
    <>
      <p className="fs-5">
        Download Options {userAgent !== "Unknown" && `(${userAgent})`}
      </p>
      <div className="dropdown">
        {userAgent === "Unknown" && (
          <p>
            We think that this application might not be compatible with your
            device. It is possible to download the installer for other devices
            down bellow.
          </p>
        )}
        {userAgent === "Macbook" && (
          <>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => download("macOS-arm")}
            >
              macOS (arm64)
            </button>
            <p></p>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => download("macOS-intel")}
            >
              macOS (Intel)
            </button>
          </>
        )}
        {userAgent === "Windows" && (
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => download("Windows")}
          >
            Windows installer
          </button>
        )}
        <p></p>

        <button
          className="btn btn-secondary dropdown-toggle"
          type="button"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {userAgent === "Unknown"
            ? "Download options for other devices"
            : `Your device is not a ${userAgent}?`}
        </button>
        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
          {userAgent !== "Macbook" && (
            <>
              <li>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={() => download("macOS-arm")}
                >
                  macOS (arm)
                </a>
              </li>
              <li>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={() => download("macOS-intel")}
                >
                  macOS (Intel)
                </a>
              </li>
            </>
          )}
          {userAgent !== "Windows" && (
            <li>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                className="dropdown-item"
                href="#"
                onClick={() => download("Windows")}
              >
                Windows installer
              </a>
            </li>
          )}
        </ul>
      </div>
    </>
  );
}

function AppDownload() {
  return (
    <motion.header
      className="py-5"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="container px-lg-5">
        <div className="p-4 p-lg-5 bg-light rounded-3 text-center">
          <div className="m-4 m-lg-5">
            <img
              src="/img/OriHeaderIllustr-1024x343.png"
              className="img-fluid"
              alt="Ilustration"
            />
          </div>
          <div className="m-4 m-lg-5">
            <div className="container">
              <div className="row">
                <div className="col-sm">
                  <h3 className="display-8 fw-bold">
                    ORI, the new Open Rehab Initiative's client application
                  </h3>
                  <p className="fs-5">
                    In order to install and use the available applications on
                    the Open Rehab Initiative, you need to download and install
                    the ORI application. Our application is available for
                    Windows device's and Macbook.
                  </p>
                </div>
                <div className="col-sm">{DownloadOptions()}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.header>
  );
}

export default AppDownload;
