import React from "react";

import { useNavigate } from "react-router-dom";

function PageFooter() {
  const navigate = useNavigate();
  const changeURL = (link: string) => {
    navigate(link);
  };

  return (
    <footer className="text-center text-lg-start bg-light text-muted">
      <section className="p-4 border-top">
        <div className="container text-center text-md-start mt-5">
          <div className="row mt-3">
            <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">
                Open Rehab Initiative
              </h6>
              <p>
                The go-to community for clinicians, scientists, engineers, game
                developers and end-users to interact with and share virtual
                rehabilitation tools.
              </p>
            </div>
            <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">Useful Links</h6>
              <p>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  className="text-reset"
                  onClick={() => changeURL("/authentication/register")}
                  style={{ cursor: "pointer" }}
                >
                  Create Account
                </a>
              </p>
              <p>
                <a
                  href="/download-app"
                  className="text-reset"
                  onClick={() => changeURL("/download-app")}
                  style={{ cursor: "pointer" }}
                >
                  ORI Client
                </a>
              </p>
              <p>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  className="text-reset"
                  onClick={() => changeURL("/applications")}
                  style={{ cursor: "pointer" }}
                >
                  Application list
                </a>
              </p>
              <p>
                <a href="/forum" className="text-reset">
                  Forum
                </a>
              </p>
              <p>
                <a className="text-reset" href="/console">
                  Publisher dashboard
                </a>
              </p>
              {/*<p>
                                <a href="#!" className="text-reset">Universal App Toolkit</a>
                            </p>
                            <p>
                                <a href="#!" className="text-reset">Developer Toolkit</a>
                            </p>*/}
            </div>
            <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">About us</h6>
              <p>
                <a href="/partners" className="text-reset">
                  Partners
                </a>
              </p>
              <p>
                <a href="/faq" className="text-reset">
                  FAQ
                </a>
              </p>
              <p>
                <a href="/funding" className="text-reset">
                  Funding
                </a>
              </p>
              <p>
                <a href="/terms-and-conditions" className="text-reset">
                  Terms and Conditions
                </a>
              </p>
              <p>
                <a href="/privacy-policy" className="text-reset">
                  Privacy Policy
                </a>
              </p>
            </div>
            <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
              <h6 className="text-uppercase fw-bold mb-4">Contact</h6>
              <p>
                <i className="fas fa-envelope me-3"></i>
                neurorehabilitation@arditi.pt
              </p>
            </div>
          </div>
        </div>
      </section>
      <div className="text-center p-4">
        © 2022 Copyright:{" "}
        <span className="text-reset fw-bold">Open Rehab Initiative</span>
      </div>
    </footer>
  );
}

export default PageFooter;
