import { FormControl, FormControlLabel, Grid, Paper, TextField, Checkbox, Button, InputLabel, OutlinedInput, InputAdornment, IconButton, Select, MenuItem, FormHelperText } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import {
    Save as SaveIcon,
    CheckCircle as CheckCircleIcon,
    Error as ErrorIcon
} from '@mui/icons-material';
import React, { ChangeEvent, useState } from 'react';

import { StyledForm } from './styles';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';

export const FormInput = (initialState: any) => {
    const [values, setValues] = useState(initialState);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        setValues({
            ...values,
            [name]: event.target.checked ? event.target.checked : value
        });
    }


    return {
        values, setValues, handleChange
    };

};

interface FormProps {
    children: any;
}
export const CustomForm: React.FC<FormProps> = ({ children }) => {
    return (
        <Paper className='mt-2'>
            <StyledForm className="bg-light p-3">
                <Grid container>{children}</Grid>
            </StyledForm>
        </Paper>
    );
}

interface SelectOption {
    title: string;
    value: string;
}
interface InputProps {
    type: string;
    name: string;
    label: any;
    placeHolder?: string;
    value: string | boolean;
    required?: boolean;
    onChange: (initialState: any) => void;
    options?: Array<SelectOption>;
    errorMessage?: string;
}
export const CustomInput: React.FC<InputProps> = ({ name, label, type, value, onChange, placeHolder, errorMessage, options }) => {
    const [aux, setAux] = useState(false);


    const renderCorrectInput = (type: string) => {
        switch (type) {
            case "select": return (!options ? <></> :
                <FormControl>
                    <InputLabel id={`${name}-label`}>{label}</InputLabel>
                    <Select
                        labelId={`${name}-label`}
                        id={name}
                        name={name}
                        value={typeof value == "string" ? value : ''}
                        label={label}
                        onChange={event => onChange(event)}
                    >
                        {options.map(({ title, value }) => (
                            <MenuItem value={value} key={`key-${value}`}>{title}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            );
            case "date": return (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                        label={label}
                        inputFormat="dd/MM/yyyy"
                        value={value}
                        onChange={date => onChange({
                            target: {
                                name: name,
                                value: date
                            }
                        })}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </LocalizationProvider>
            );
            case "checkbox": return (
                <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined"
                    error={errorMessage !== undefined}>
                    <FormControlLabel
                        control={<Checkbox
                            name={name}
                            color="primary"
                            checked={typeof value == "boolean" ? value : false}
                            onChange={event => onChange({
                                target: {
                                    name,
                                    value: event.target.checked
                                }
                            })}
                        />}
                        label={label}
                    />
                    {errorMessage &&
                        <FormHelperText error id={`${name}-error`}>
                            {errorMessage}
                        </FormHelperText>
                    }
                </FormControl>
            );
            case "password":
                return (
                    <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined"
                        error={errorMessage !== undefined}>
                        <InputLabel htmlFor={name}>{label}</InputLabel>
                        <OutlinedInput
                            id={name}
                            label={label}
                            name={name}
                            type={aux ? 'text' : 'password'}
                            value={typeof value == "string" ? value : ''}
                            onChange={event => onChange(event)}
                            placeholder={placeHolder}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setAux(!aux)}
                                        onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) => {
                                            event.preventDefault();
                                        }}
                                        edge="end"
                                    >
                                        {aux ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        {errorMessage &&
                            <FormHelperText error id={`${name}-error`}>
                                {errorMessage}
                            </FormHelperText>
                        }
                    </FormControl>
                );
            case "text": case "email": return (
                <TextField
                    type={type}
                    name={name}
                    label={label}
                    placeholder={placeHolder}
                    value={typeof value == "string" ? value : ''}
                    onChange={onChange}
                    variant='outlined'
                    error={errorMessage !== undefined}
                    helperText={errorMessage}
                />
            );
            default: return (<></>);
        }
    }
    return renderCorrectInput(type);
};

interface ButtonProps {
    label: string;
    color: "primary" | "inherit" | "error" | "secondary" | "info" | "success" | "warning" | undefined;
    onClick?: () => void;
    icon?: string;
    disabled?: boolean;
}
export const CustomButton: React.FC<ButtonProps> = ({ label, color, onClick, icon, disabled }) => {
    const renderCorrectInput = (icon: string) => {
        switch (icon) {
            case "loading": return (
                <LoadingButton
                    className='m-2'
                    onClick={onClick}
                    endIcon={<SaveIcon />}
                    loading
                    loadingPosition="end"
                    variant="contained"
                >{label}</LoadingButton>
            );
            case "success": return (
                <Button
                    className='m-2'
                    variant="contained"
                    color="success"
                    onClick={onClick}
                    disabled={disabled}
                ><span>{label}  <CheckCircleIcon /></span></Button>
            );
            case "error": return (
                <Button
                    className='m-2'
                    variant="contained"
                    color="error"
                    onClick={onClick}
                    disabled={disabled}
                ><span>{label}  <ErrorIcon /></span></Button>
            );

            default: return (
                <Button
                    className='m-2'
                    variant="contained"
                    color={color}
                    onClick={onClick}
                    disabled={disabled}
                >{label}</Button>
            );
        };
    };

    return icon ? renderCorrectInput(icon) : (
        <Button
            className='m-2'
            variant="contained"
            color={color}
            onClick={onClick}
            disabled={disabled}
        >{label}</Button>
    );

};
