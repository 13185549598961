import { Alert, AlertColor, Grid } from '@mui/material';

import React, { useState } from 'react';

import { FormInput as formInput, CustomForm, CustomInput, CustomButton } from '../../../Fields/FormToolBox';
import Small from '../../../Bootstrap/Small';
// import Captcha from '../../../Captcha';

declare global {
    interface Window { grecaptcha: any; }
}

const languageParser = (languageCode: string) => {
    let languageParser = new Intl.DisplayNames([languageCode], {
        type: 'language'
    });
    return {
        title: languageParser.of(languageCode) || '',
        value: languageCode || ''
    }
}

const availableLanguages = [
    languageParser('en-US'),
    languageParser('pt-PT'),
    //languageParser('es-ES')
];
const defaultRegisterButtonParams = {
    icon: 'none',
    text: 'Register'
};
interface FeedbackParams {
    icon: AlertColor | 'none';
    text: string;
}
const defaultFeedbackParams: FeedbackParams = {
    icon: 'none',
    text: 'Register'
};
const defaultIsResetButtonDisabled = false;
const defaultFormValues = {
    username: '',
    firstName: '',
    lastName: '',
    email: '',
    language: availableLanguages.some(languageCode => JSON.stringify(languageCode) === JSON.stringify(languageParser(navigator.language))) ?
        navigator.language + '' : 'en-US',
    password: '',
    confirmPassword: '',
    agreedToTermsAndConditions: false,
    birthDate: new Date()
};
interface JSONObject {
    [key: string]: any
}
const defaultFormErrors: JSONObject = {
    username: undefined,
    firstName: undefined,
    lastName: undefined,
    email: undefined,
    language: undefined,
    password: undefined,
    confirmPassword: undefined,
    agreedToTermsAndConditions: undefined,
    birthDate: undefined
};

interface RegisterFormProps {
    setOperation: React.Dispatch<React.SetStateAction<string>>;
}
const RegisterForm: React.FC<RegisterFormProps> = ({ setOperation }) => {
    const {
        values, setValues, handleChange
    } = formInput(defaultFormValues);
    // const [captchaToken, setCaptchaToken] = useState('');
    const [inputErrors, setInputErrors] = useState(defaultFormErrors);
    const [registerButton, setRegisterButton] = useState(defaultRegisterButtonParams);
    const [formFeedback, setFormFeedback] = useState<FeedbackParams>(defaultFeedbackParams);
    const [isResetButtonDisabled, setIsResetButtonDisabled] = useState(defaultIsResetButtonDisabled);

    const changeRegisterButton = (icon = defaultRegisterButtonParams.icon, text = defaultRegisterButtonParams.text) => {
        setRegisterButton({
            ...registerButton,
            icon, text
        });
    };
    const changeFormFeedback = (icon = defaultFeedbackParams.icon, text = defaultFeedbackParams.text) => {
        setFormFeedback({
            ...formFeedback,
            icon, text
        });
    }

    const resetForm = () => {
        setValues(defaultFormValues);
        setInputErrors(defaultFormErrors);
        setRegisterButton(defaultRegisterButtonParams);
        setIsResetButtonDisabled(defaultIsResetButtonDisabled);
        window.grecaptcha.reset();
    };

    const submitForm = () => {
        let errorMsgs: JSONObject = { ...defaultFormErrors };

        //Note: Make all necessary checks before checking for blank fields

        //Check if password fields match
        if (values.password !== values.confirmPassword)
            errorMsgs.confirmPassword = 'Password and its confirmation do not match';

        //Check if the user accepted the terms and conditions
        if (!values.agreedToTermsAndConditions)
            errorMsgs.agreedToTermsAndConditions = 'You need to agree to the terms and conditions';

        //If previous checks pass, verify if any field was left blank
        Object.keys(values).forEach(inputFieldName => {
            if (values[inputFieldName] === '') {
                errorMsgs[inputFieldName] = 'This field cannot be blank';
            }
        });

        setInputErrors(errorMsgs);

        let noEmptyFields = Object.keys(errorMsgs).map(value => errorMsgs[value] === undefined).every(v => v === true);

        if (!noEmptyFields) return; //Stop execution if there are empty fields


        changeRegisterButton("loading", "Registering");
        setIsResetButtonDisabled(true);

        let reqBodyValues = {...values};
        reqBodyValues.birthDate = +reqBodyValues.birthDate;

        fetch('/api/v1/account/auth/signup', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                ...reqBodyValues,
                //reCaptcha: captchaToken
            })
        }).then(response => response.json()
        ).then(data => {
            console.log(data);
            if (data.success === true) {
                changeRegisterButton("success", "Registered");
                setIsResetButtonDisabled(false);
                changeFormFeedback("success", "Registered! Please validate your email before logging in.");
            } else {
                setRegisterButton(defaultRegisterButtonParams);
                setIsResetButtonDisabled(false);
                changeFormFeedback("error", `Could create your new account: ${data.reason}`);
                window.grecaptcha.reset();
            }
        });

    }

    return (
        <CustomForm>
            <Grid item xs={6} style={{ textAlign: "center" }}>
                <CustomInput
                    name='username'
                    label="Username"
                    placeHolder='Username'
                    value={values.username}
                    onChange={handleChange}
                    type="text"
                    errorMessage={inputErrors.username}
                />
                <CustomInput
                    name='firstName'
                    label="First Name"
                    placeHolder='First Name'
                    value={values.firstName}
                    onChange={handleChange}
                    type="text"
                    errorMessage={inputErrors.firstName}
                />
                <CustomInput
                    name='lastName'
                    label="Last Name"
                    placeHolder='Last Name'
                    value={values.lastName}
                    onChange={handleChange}
                    type="text"
                    errorMessage={inputErrors.lastName}
                />
                <CustomInput
                    name='email'
                    label="Email"
                    placeHolder='example@email.org'
                    value={values.email}
                    onChange={handleChange}
                    type="email"
                    errorMessage={inputErrors.email}
                />
            </Grid>
            <Grid item xs={6} style={{ textAlign: "center" }}>
                <CustomInput
                    name="birthDate"
                    label={"Birth Date"}
                    value={values.birthDate}
                    onChange={handleChange}
                    type="date"
                />
                <CustomInput
                    name="language"
                    label="Language"
                    value={values.language}
                    onChange={handleChange}
                    type="select"
                    options={availableLanguages}
                />
                <CustomInput
                    type="password"
                    name="password"
                    label="Password"
                    value={values.password}
                    onChange={handleChange}
                    placeHolder="********"
                    errorMessage={inputErrors.password}
                />
                <CustomInput
                    type="password"
                    name="confirmPassword"
                    label="Confirm Password"
                    value={values.confirmPassword}
                    onChange={handleChange}
                    placeHolder="********"
                    errorMessage={inputErrors.confirmPassword}
                />
                <CustomInput
                    type="checkbox"
                    onChange={handleChange}
                    name='agreedToTermsAndConditions'
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    label={<>I agree with the <a href="/terms-and-conditions">Terms and Conditions</a> and the <a href="/privacy-policy">Privacy Policy</a></>}
                    value={values.agreedToTermsAndConditions}
                    errorMessage={inputErrors.agreedToTermsAndConditions}
                />
            </Grid>
            <Grid item xs={12}>
                {/**<Captcha onChange={(token => {
                    if (token) setCaptchaToken(token);
                })} />**/}
            </Grid>
            <Grid item xs={12}>
                <CustomButton
                    label={registerButton.text}
                    color="primary"
                    onClick={submitForm}
                    icon={registerButton.icon}
                />
                <CustomButton
                    label="Reset"
                    color="inherit"
                    onClick={resetForm}
                    disabled={isResetButtonDisabled}
                />
                {formFeedback.icon !== "none" &&
                    <Alert severity={formFeedback.icon}>{formFeedback.text}</Alert>
                }
            </Grid>
            <Grid item xs={12}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Small>Already registered? Click <a href='#' onClick={() => setOperation('LOGIN')}>here</a></Small>
            </Grid>
        </CustomForm>
    )
}

export default RegisterForm;