import React from 'react';
import { motion } from 'framer-motion';

function HomePage() {

    return (
        <motion.header className="py-5" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <div className="container px-lg-5">
                <div className="p-4 p-lg-5 bg-light rounded-3 text-center">
                    <div className="m-4 m-lg-5">
                        <h1 className="display-5 fw-bold">Not found</h1>
                        <p>
                            The requested page was not found
                        </p>

                    </div>
                </div>
            </div>
        </motion.header>
    );
}

export default HomePage;