import React from "react";
import { motion } from "framer-motion";

import { Topic, TopicImage } from "./styles";
import { useNavigate } from "react-router-dom";

function HomePage() {
  const navigate = useNavigate();
  const changeURL = (link: string) => {
    navigate(link);
  };
  return (
    <motion.header
      className="py-5"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="container px-lg-5">
        <div className="p-4 p-lg-5 bg-light rounded-3 text-center">
          <div className="m-4 m-lg-5">
            <img
              src="/img/OriHeaderIllustr-1024x343.png"
              className="img-fluid"
              alt="Ilustration"
            />
          </div>
          <div className="m-4 m-lg-5">
            <h1 className="display-5 fw-bold">Open Rehab Initiative</h1>
            <p className="fs-4">
              The go-to community for clinicians, scientists, engineers, game
              developers and end-users to interact with and share virtual
              rehabilitation tools.
            </p>
            <p className="fs-6">
              A user-friendly portal where the community who build and use
              software tools for virtual rehabilitation can easily communicate,
              interact and share with these tools. Here you will find software,
              drivers, and documentation of evidence and application, discussion
              boards, blogs.
            </p>

            <div className="row">
              <Topic className="col-sm">
                <TopicImage
                  src="/img/upper_limb1-1-e1561365496622-300x298.jpg"
                  className="rounded float-start"
                  alt="Upper Limb"
                  onClick={() => changeURL("/applications/Upper Limb")}
                />
              </Topic>
              <Topic className="col-sm">
                <TopicImage
                  src="/img/fitness1-1-e1561365511870-297x300.jpg"
                  className="rounded float-end"
                  alt="Fitness"
                  onClick={() => changeURL("/applications/Fitness")}
                />
              </Topic>
              <Topic className="col-sm">
                <TopicImage
                  src="/img/balance1-1-e1561365564159-297x300.jpg"
                  className="rounded float-end"
                  alt="Balance"
                  onClick={() => changeURL("/applications/Balance")}
                />
              </Topic>
              <Topic className="col-sm">
                <TopicImage
                  src="/img/cognitive1-1-e1561365525742-300x300.jpg"
                  className="rounded float-end"
                  alt="Cognition"
                  onClick={() => changeURL("/applications/Cognition")}
                />
              </Topic>
              <Topic className="col-sm">
                <TopicImage
                  src="/img/mobility1-1-298x300.jpg"
                  className="rounded float-end"
                  alt="Mobility"
                  onClick={() => changeURL("/applications/Mobility")}
                />
              </Topic>
            </div>
          </div>
        </div>
      </div>
    </motion.header>
  );
}

export default HomePage;
