import React, { useState } from "react";
import { motion } from "framer-motion";

import RegisterForm from "./RegisterForm";
import LoginForm from "./LoginForm";
import RecoverPasswordChangeForm from './RecoverPasswordChangeForm';
import RecoverPasswordForm from "./RecoverPasswordForm";
import { useParams } from "react-router-dom";

interface Register_LoginProps {
  enableAppMode: boolean;
}
const Register_Login: React.FC<Register_LoginProps> = ({ enableAppMode }) => {
  const params = useParams();
  
  // Select current form
  const [operation, setOperation] = useState(
    (() => {
      switch(params.operation){
        case "register": return "REGISTER";
        case "login": return "LOGIN";
        case "reset_password": return "RESET PASSWORD";
        case "reset_password_change": return "CHANGE PASSWORD";
        default: return "LOGIN";
      }
    })
  );
  //Select current redirect (if exists)
  const [redirect] = useState(useParams().redirect?.toLowerCase() || undefined);

  return (
    <motion.header
      className="py-5"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="container px-lg-5">
        <div className="p-4 p-lg-3 bg-light rounded-3 text-center">
          <div className="m-4 m-lg-1">
            <h1>{operation}</h1>
          </div>
        </div>
        {operation === "REGISTER" && (
          <RegisterForm setOperation={setOperation} />
        )}
        {operation === "LOGIN" && (
          <LoginForm
            setOperation={setOperation}
            enableAppMode={enableAppMode}
            redirect={redirect}
          />
        )}
        {operation === "RESET PASSWORD" && (
          <RecoverPasswordForm
            setOperation={setOperation}
          />
        )}
        {operation === "CHANGE PASSWORD" && (
          <RecoverPasswordChangeForm
            setOperation={setOperation}
            resetToken={redirect || ""}
          />
        )}
      </div>
    </motion.header>
  );
};

export default Register_Login;
