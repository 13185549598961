import styled from "styled-components"

export const Topic = styled.a`
    margin: 10px;
    cursor: auto;
`;

export const TopicImage = styled.img`
    border-radius: 20px !important;
    width: 100%;
    height: 100%;
    cursor: pointer;

    &:hover{
        filter: brightness(40%);
        transition-duration: 300ms;
    }
`;

export const MarkdownHolder = styled.div`
    max-height: 200px;
    overflow-y: scroll;
`