import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

//Components
import Questions from './Components/Questions';

function FAQPage() {
    const [faq, setFAQ] = useState([]);

    useEffect(() => {
        fetch("/api/v1/FAQ")
            .then(response => response.json())
            .then(function (data) {
                if (data.status === false) {
                    return console.log("Error: Cannot fetch FAQ's");
                }

                setFAQ(data.result);
            })
            .catch(function (error) {
                console.log("Error: Cannot fetch FAQ's ", error);
            });
    }, []);

    return (
        <motion.header className="py-5" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <div className="container px-lg-5">
                <div className="p-4 p-lg-5 bg-light rounded-3 text-center">
                    <div className="m-4 m-lg-5">
                        <h1>Frequently Asked Questions</h1>
                    </div>
                </div>
                <Questions questions={faq
                    /**[
                    {
                        question: 'Is there a file size limit for uploading my application?',
                        answer: 'Yes. At the moment ORI only allows files with size under 400 mb. If your aplication has more than 400 mb please contact us (openrehabinitiative@gmail.com)'
                    },
                    {
                        question: 'I am having issues with the software that I downloaded. Who should I contact for assistance?',
                        answer: `Some applications provide technical assistance contact, you can find that information at the bottom of the application page next to the reviews. If the publisher didn't provide a contact for technical support you can always try to contact the publisher directly. The name of the publisher can be found at the top under the application name.`
                    },
                    {
                        question: 'Can I publish my own applications?',
                        answer: `Only affiliated users can submit applications to ORI website. If your lab/group is already an ORI member, you can change your role on your account settings and choose your affiliation. If your lab/group is not yet an ORI member and if you are the lab/group manager you can apply to become an ORI member. You'll be then notified by ORI administration within 2 working days.`
                    },
                    {
                        question: `I've updated my application but I can't see the changes.`,
                        answer: `You may need to clear your browser cache.`
                    }
                ]**/} />
            </div>
        </motion.header>
    );
}

export default FAQPage;