import { Alert, Grid } from "@mui/material";

import React, { useState } from "react";

import {
  CustomForm,
  CustomInput,
  CustomButton,
} from "../../../Fields/FormToolBox";
import Small from "../../../Bootstrap/Small";
// import Captcha from "../../../Captcha";

declare global {
  interface Window {
    grecaptcha: any;
    electron: {
      language(): string;
      ipcRenderer: {
        sendMessage(channel: string, args: unknown[]): void;
      };
    };
  }
}

interface LoginFormProps {
  setOperation: React.Dispatch<React.SetStateAction<string>>;
}
const LoginForm: React.FC<LoginFormProps> = ({
  setOperation,
}) => {
  const [email, setEmail] = useState('');
  const [feedback, setFeedback] = useState('');
  const [feedbackType, setFeedbackType] = useState<"success" | "error">('success');

  const submit = () => {
    const url = `/api/v1/account/auth/resetPassword`;
    const params = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email
      }),
    };

    fetch(url, params)
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      if (data.success === true) {
        setFeedbackType("success");
        setFeedback("Password reset request has been sent! Check your email inbox for more details.");
      }else{
        setFeedbackType("error");
        setFeedback(`Could not sent the request password request: ${data.reason}`);
      }
    });
  }

  return (
    <CustomForm>
      <Grid item xs={6} style={{ textAlign: "center" }}>
        <CustomInput
          name="email"
          label="Email"
          placeHolder="example@email.com"
          value={email}
          onChange={(evt:any) => setEmail(evt.target.value)}
          type="text"
        />
      </Grid>
      <Grid item xs={12}>
        {/**<Captcha onChange={(token => {
                    if (token) setCaptchaToken(token);
                })} />**/}
      </Grid>
      <Grid item xs={12} style={{ textAlign: "left" }}>
        <CustomButton
          label="Reset Password"
          color="primary"
          onClick={() => submit()}
        />
        {feedback !== "" && (
          <Alert severity={feedbackType}>{feedback}</Alert>
        )}
      </Grid>
      <Grid item xs={12}>
        <Small>
          Not registered? Click{" "}
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a href="#" onClick={() => setOperation("REGISTER")}>
            here
          </a>
        </Small>
      </Grid>
    </CustomForm>
  );
};

export default LoginForm;
