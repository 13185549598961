import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import ReactMarkdown from 'react-markdown'

import { Topic, TopicImage } from "./styles";

function HomePage() {
  const [partners, setPartners] = useState<
    Array<{ logo: string; name: string; description: string; website: string }>
  >([]);
  const [currentPartner, setCurrentPartner] = useState(0);
  const [isLoadingPartners, setIsLoadingPartners] = useState(true);

  // Set current viewing partner
  const setCurrentViewingPartner = (index: number, event: any) => {
    event.preventDefault();

    // Check if partner information is being shown
    if (
      document.getElementById("information_card")?.className.includes("show")
    ) {
      // If we click on the partner that has his information being shown, hide the partner information
      if (currentPartner === index) {
        new (window as any).bootstrap.Collapse(
          document.getElementById("information_card")
        );
      }
      setCurrentPartner(index);
    } else {
      // If partner information is not being show, set partner id and open the information card
      setCurrentPartner(index);
      new (window as any).bootstrap.Collapse(
        document.getElementById("information_card")
      );
    }
  };

  const getPartners = () => {
    fetch("/api/v1/organization/all", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setPartners(data);
        setIsLoadingPartners(false);
        console.log(data);
      });
  };

  useEffect(() => {
    getPartners();

    const interval = setInterval(() => {
      getPartners();
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <motion.header
      className="py-5"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="container px-lg-5">
        <div className="p-4 p-lg-5 bg-light rounded-3 text-center">
          <div className="m-4 m-lg-5">
            <img
              src="/img/OriHeaderIllustr-1024x343.png"
              className="img-fluid"
              alt="Ilustration"
            />
          </div>
          <div className="m-4 m-lg-5">
            <h1 className="display-5 fw-bold">Partners</h1>

            <div className="row" style={{ textAlign: "center" }}>
              {isLoadingPartners === true && (
                <Box>
                  <CircularProgress />
                </Box>
              )}

              {partners.map(({ logo, name }, index) => {
                return (
                  <Topic
                    className="col-sm"
                    key={`logo_${index}`}
                    aria-expanded="false"
                    aria-controls="information_card"
                    onClick={(e) => {
                      setCurrentViewingPartner(index, e);
                    }}
                  >
                    <TopicImage
                      src={logo}
                      style={{ width: "90%" }}
                      className="rounded float-start"
                      alt={name}
                    />
                  </Topic>
                );
              })}
            </div>
            {partners.length > 0 && (
              <div className="collapse" id="information_card">
                <div className="card mb-3">
                  <h2 className="card-header text-muted">
                    About {partners[currentPartner].name}
                  </h2>
                  <div className="card-body text-dark lead">
                    <p className="card-text text-start">
                      <ReactMarkdown>
                        {partners[currentPartner].description}
                      </ReactMarkdown>
                    </p>
                    <p>
                      Partner website:{" "}
                      <a
                        href={partners[currentPartner].website}
                        className="link-primary"
                      >
                        {partners[currentPartner].website}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </motion.header>
  );
}

export default HomePage;
