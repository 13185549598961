import React from "react";

import { NavBarLogo } from "./styles";
import { useNavigate } from "react-router-dom";

interface Props {
  items: Array<Paths>;
}
interface Paths {
  path: string;
  title: string;
  sameWebsite: boolean;
}
const NavBar: React.FC<Props> = ({ items }) => {
  let navigate = useNavigate();

  const changePage = (link: string, isSameWebsite: boolean) => {
    if (isSameWebsite === true) navigate(link);
    else {
      window.location.href = link;
    }
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top">
      <div className="container px-lg-5">
        <a className="navbar-brand" href="/">
          <NavBarLogo
            src="/img/cropped-cropped-ORI-Logo-32x32.png"
            alt=""
            width="30"
            height="24"
            className="d-inline-block align-text-top"
          />
          Open Rehab Initiative
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            {items.map(({ path, title, sameWebsite }, index) => {
              return (
                <li className="nav-item" key={`navbar_${index}`}>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    className="nav-link"
                    aria-current="page"
                    onClick={() => changePage(path, sameWebsite)}
                    style={{ cursor: "pointer" }}
                  >
                    {title}
                  </a>
                </li>
              );
            })}
            {/*<li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button"
                                data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="fa-solid fa-magnifying-glass"></i>
                            </a>
                            <SearchBar className="dropdown-menu dropdown-menu-end">
                                <li className="input-group mb-3">
                                    <input type="text" className="form-control" placeholder="Search"
                                        aria-label="Search" aria-describedby="button-addon2" />
                                    <button className="btn btn-outline-secondary" type="button"
                                        id="button-addon2"><i className="fa-solid fa-magnifying-glass"></i></button>
                                </li>
                    </SearchBar>
                        </li >*/}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
