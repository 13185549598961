import React from 'react';

interface ReceivedQuestion {
    question: string;
    answer: string;
    id: string;
}
const Question: React.FC<ReceivedQuestion> = ({ question, answer, id }) => {
    return (
        <div className="accordion-item">
            <h2 className="accordion-header" id={`${id}_header`}>
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#${id}_collapse`} aria-expanded="false" aria-controls={`${id}_collapse`}>
                    {question}
                </button>
            </h2>
            <div id={`${id}_collapse`} className="accordion-collapse collapse" aria-labelledby={`${id}_header`} data-bs-parent="#FAQ_Questions">
                <div className="accordion-body">{answer}</div>
            </div>
        </div>
    )
}

export default Question;