import styled from "styled-components"

export const NavBarLogo = styled.img`
    border-radius: 50%;
    margin-right: 10px;
`;

export const SearchBar = styled.ul`
    padding: 10px;
    min-width: 20rem !important;
`;