import { Alert, AlertColor, Grid } from "@mui/material";

import React, { useState } from "react";

import {
  FormInput as formInput,
  CustomForm,
  CustomInput,
  CustomButton,
} from "../../../Fields/FormToolBox";
import Small from "../../../Bootstrap/Small";
// import Captcha from "../../../Captcha";

declare global {
  interface Window {
    grecaptcha: any;
    electron: {
      language(): string;
      ipcRenderer: {
        sendMessage(channel: string, args: unknown[]): void;
      };
    };
  }
}

const defaultLoginButtonParams = {
  icon: "none",
  text: "Login",
};
interface FeedbackParams {
  icon: AlertColor | "none";
  text: string;
}
const defaultFeedbackParams: FeedbackParams = {
  icon: "none",
  text: "Login",
};
const defaultIsResetButtonDisabled = false;
const defaultFormValues = {
  email: "",
  neverExpire: false,
  password: "",
};
interface JSONObject {
  [key: string]: any;
}
const defaultFormErrors: JSONObject = {
  email: undefined,
  neverExpire: undefined,
  password: undefined,
};

interface LoginFormProps {
  setOperation: React.Dispatch<React.SetStateAction<string>>;
  redirect?: string;
  enableAppMode: boolean;
}
const LoginForm: React.FC<LoginFormProps> = ({
  setOperation,
  redirect,
  enableAppMode,
}) => {
  const { values, setValues, handleChange } = formInput(defaultFormValues);
  const [captchaToken] = useState("");
  const [inputErrors, setInputErrors] = useState(defaultFormErrors);
  const [loginButton, setLoginButton] = useState(defaultLoginButtonParams);
  const [formFeedback, setFormFeedback] = useState<FeedbackParams>(
    defaultFeedbackParams
  );
  const [isResetButtonDisabled, setIsResetButtonDisabled] = useState(
    defaultIsResetButtonDisabled
  );

  const changeLoginButton = (
    icon = defaultLoginButtonParams.icon,
    text = defaultLoginButtonParams.text
  ) => {
    setLoginButton({
      ...loginButton,
      icon,
      text,
    });
  };
  const changeFormFeedback = (
    icon = defaultFeedbackParams.icon,
    text = defaultFeedbackParams.text
  ) => {
    setFormFeedback({
      ...formFeedback,
      icon,
      text,
    });
  };

  const resetForm = () => {
    setValues(defaultFormValues);
    setInputErrors(defaultFormErrors);
    setLoginButton(defaultLoginButtonParams);
    setIsResetButtonDisabled(defaultIsResetButtonDisabled);
    window.grecaptcha.reset();
  };

  const submitForm = () => {
    let errorMsgs: JSONObject = { ...defaultFormErrors };

    //Note: Make all necessary checks before checking for blank fields

    //Check if the user accepted the terms and conditions
    if (!values.agreedToTermsAndConditions)
      errorMsgs.agreedToTermsAndConditions =
        "You need to agree to the terms and conditions";

    //If previous checks pass, verify if any field was left blank
    Object.keys(values).forEach((inputFieldName) => {
      if (values[inputFieldName] === "") {
        errorMsgs[inputFieldName] = "This field cannot be blank";
      }
    });

    setInputErrors(errorMsgs);

    let noEmptyFields = Object.keys(errorMsgs)
      .map((value) => errorMsgs[value] === undefined)
      .every((v) => v === true);

    if (!noEmptyFields) return; //Stop execution if there are empty fields

    changeLoginButton("loading", "Loging");
    setIsResetButtonDisabled(true);

    console.log("Submited: ", values);
    fetch("/api/v1/account/auth/login", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...values,
        reCaptcha: captchaToken,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.success === true) {
          changeLoginButton("success", "Logged in!");
          setIsResetButtonDisabled(false);
          changeFormFeedback("success", "Logged in! Redirecting...");

          if (window.electron?.ipcRenderer?.sendMessage !== undefined)
            window.electron?.ipcRenderer?.sendMessage("account-setToken", []);
          else if (redirect !== undefined) {
            switch (redirect) {
              default:
                break;
              case "api_docs":
                window.location.replace("/api/docs");
                break;
              case "forum":
                window.location.replace("/forum");
                break;
            }
          }
        } else {
          changeLoginButton(); //Set default options
          setIsResetButtonDisabled(false);
          changeFormFeedback("error", `Could not login: ${data.reason}`);
          window.grecaptcha.reset();
        }
      });
  };

  return (
    <CustomForm>
      <Grid item xs={6} style={{ textAlign: "center" }}>
        <CustomInput
          name="email"
          label="Email or Username"
          placeHolder="example@email.com"
          value={values.email}
          onChange={handleChange}
          type="text"
          errorMessage={inputErrors.email}
        />
        <CustomInput
          type="checkbox"
          onChange={handleChange}
          name="neverExpire"
          label={<>Save password</>}
          value={values.neverExpire}
          errorMessage={inputErrors.neverExpire}
        />
      </Grid>
      <Grid item xs={6} style={{ textAlign: "center" }}>
        <CustomInput
          type="password"
          name="password"
          label="Password"
          value={values.password}
          onChange={handleChange}
          placeHolder="********"
          errorMessage={inputErrors.password}
        />
        <CustomInput
          type="checkbox"
          onChange={handleChange}
          name="agreedToTermsAndConditions"
          label={
            <>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}I agree
              with the <a href="/terms-and-conditions">Terms and Conditions</a> and the <a href="/privacy-policy">Privacy Policy</a>
            </>
          }
          value={values.agreedToTermsAndConditions}
          errorMessage={inputErrors.agreedToTermsAndConditions}
        />
      </Grid>
      <Grid item xs={12}>
        {/**<Captcha onChange={(token => {
                    if (token) setCaptchaToken(token);
                })} />**/}
      </Grid>
      <Grid item xs={12} style={{ textAlign: "left" }}>
        <CustomButton
          label={loginButton.text}
          color="primary"
          onClick={submitForm}
          icon={loginButton.icon}
        />
        <CustomButton
          label="Reset"
          color="inherit"
          onClick={resetForm}
          disabled={isResetButtonDisabled}
        />
        {formFeedback.icon !== "none" && (
          <Alert severity={formFeedback.icon}>{formFeedback.text}</Alert>
        )}
        < br/>
        <Small>
          Forgot Password? Click{" "}
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a href="#" onClick={() => setOperation("RESET PASSWORD")}>
            here
          </a>
        </Small>
      </Grid>
      <Grid item xs={12}>
        <Small>
          Not registered? Click{" "}
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a href="#" onClick={() => setOperation("REGISTER")}>
            here
          </a>
        </Small>
      </Grid>
    </CustomForm>
  );
};

export default LoginForm;
