import React from 'react';

interface SubmitButton {
    children: React.ReactNode
}

const Question: React.FC<SubmitButton> = ({ children }) => {
    return (
        <small className="text-muted">{children}</small>
    )
}

export default Question;