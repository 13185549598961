import React from 'react';

//Components
import Question from './Question';

interface ReceivedQuestion {
    question: string;
    answer: string;
}

interface ReceivedQuestions {
    questions: ReceivedQuestion[];
}


const Questions: React.FC<ReceivedQuestions> = ({ questions }) => {
    return (
        <div className="accordion" id="FAQ_Questions">
            {
                questions.map((value, index) => {
                    return (
                        <Question
                            question={value.question}
                            answer={value.answer}
                            key={`question_${index}`}
                            id={`question_${index}`}
                        />
                    )
                })
            }
        </div>
    )
}

export default Questions;