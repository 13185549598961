import React, { useState } from 'react';
import { motion } from 'framer-motion';

import { Topic, TopicImage } from './styles';

const defaultPartners = [
    {
        logo: "/img/footer/image.png",
        name: "Rutgers"
    },
    {
        logo: "/img/footer/unnamed-1.jpg",
        name: "European Union Interreg"
    },
    {
        logo: "/img/footer/unnamed.jpg",
        name: "Neurorehabilitation & Brain Research Group"
    }
]

function HomePage() {
    const [partners] = useState(defaultPartners);

    return (
        <motion.header className="py-5" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <div className="container px-lg-5">
                <div className="p-4 p-lg-5 bg-light rounded-3 text-center">
                    <div className="m-4 m-lg-5">
                        <img src="/img/OriHeaderIllustr-1024x343.png" className="img-fluid" alt="Ilustration" />
                    </div>
                    <div className="m-4 m-lg-5">
                        <h1 className="display-5 fw-bold">Funding</h1>
                        <p>Open Rehab Initiative has been partially funded by
                            MACBIOIDI: Promoting the cohesion of Macaronesian regions
                            through a common ICT platform for biomedical
                            R & D & i” (INTERREG program MAC/1.1.b/098) and
                            by Rutgers International Collaborative Research Grant -
                            “Open Rehabilitation Initiative: Usability and Knowledge Translation”.
                        </p>

                        <div className="row">
                            {partners.map(({ logo, name }, index) => {
                                return (
                                    <Topic
                                        className="col-sm"
                                    >
                                        <TopicImage
                                            src={logo}
                                            className="rounded float-start"
                                            alt={name}
                                            style={{ cursor: "default" }}
                                        />
                                    </Topic>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </motion.header>
    );
}

export default HomePage;