import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useNavigate, useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import { MarkdownHolder } from "./styles";

function AppDownload() {
  /*function download(selectedOS: string) {
    const url = `/app/update/${selectedOS}`;
    const latestFile = `${url}/latest${
      selectedOS.includes("darwin") ? "-mac" : ""
    }.yml`;
    fetch(latestFile)
      .then((r) => r.text())
      .then((t) => {
        const fileName = YAML.parse(t).path;
        const fileDownloadURL = `${window.location.protocol}//${window.location.host}${url}/${fileName}`;
        window.open(new URL(fileDownloadURL));
      });
  }*/

  /*function getUserAgent() {
    var ua = navigator.userAgent.toLowerCase();
    //if (/android/i.test(ua))
    //    return 'Android';
    if (/mac/i.test(ua)) return "Macbook";
    if (/windows/i.test(ua)) return "Windows device";

    return "Unknown";
  }*/

  const [categories] = useState<Array<string>>([
    "Balance",
    "Cognition",
    "Upper Limb",
    "Mobility",
    "Fitness",
  ]);

  interface SoftwareListItem {
    id: string;
    software: string;
    description: string;
    owners: Array<{ name: string; website: string }>;
    categories: Array<string>;
    logo: string;
  }
  const [softwareList, setSoftwareList] = useState<Array<SoftwareListItem>>([
    /*{
      id: "com.musi.msu1",
      software: "Musiquence",
      description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit.
      Sed at convallis lacus. Nunc semper lobortis nunc et
      imperdiet. Cras purus nulla, tincidunt ac maximus at,
      dignissim in ipsum.`,
      owner: {
        name: "NeuroRehabLab",
        website: "https://example.org",
      },
      category: ["Cognition"],
      logo: "",
    }*/
  ]);
  const [currentFilter, setCurrentFilter] = useState<string>(
    useParams().filter || ""
  );

  function getCatalog() {
    fetch("/api/v1/software/websiteCatalog", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.success === true) setSoftwareList(data.softwares);
      });
  }
  useEffect(() => {
    getCatalog();
  }, []);

  let navigate = useNavigate();
  const openORIDownloadPage = () => {
    navigate(`/download-app`);
  };

  return (
    <motion.header
      className="py-5"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div
        className="modal fade"
        id="downloadOriAppModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-labelledby="downloadOriAppModal"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="downloadOriAppModalLabel">
                ORI Client application required
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              In order to install and use this software, the ORI Client
              application is required. The ORI client application automaticaly
              downloads, installs and configures the software for you.
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => {
                  document.location = `ori://open`;
                }}
              >
                Already have installed
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => openORIDownloadPage()}
                data-bs-dismiss="modal"
              >
                Install
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col text-center my-4">
            <h1 className="fs-2">Applications</h1>
            <div className="underline mx-auto mt-3"></div>
          </div>
        </div>

        {/** App Filter options */}
        <div className="row mt-3 mb-4 button-group filter-button-group">
          <div className="col d-flex justify-content-center">
            <button
              type="button"
              className={`btn ${
                currentFilter === "" ? "btn-secondary" : "btn-light"
              }`}
              style={{ margin: "5px" }}
              onClick={() => setCurrentFilter("")}
            >
              All
            </button>
            {categories.map((value: string) => (
              <button
                type="button"
                className={`btn ${
                  currentFilter === value ? "btn-secondary" : "btn-light"
                }`}
                style={{ margin: "5px" }}
                onClick={() => setCurrentFilter(value)}
              >
                {value}
              </button>
            ))}
          </div>
        </div>

        <div className="row justify-content-center g-4" id="product-list">
          {softwareList
            .filter((softwareItem: SoftwareListItem) =>
              currentFilter === ""
                ? true
                : softwareItem.categories.includes(currentFilter)
            )
            .map((softwareItem: SoftwareListItem) => (
              <div
                className="card mb-3"
                style={{ maxWidth: "540px", margin: "10px" }}
              >
                <div className="card-body">
                  <h5 className="card-title">
                    <img
                      src={softwareItem.logo}
                      className="img-fluid rounded-start"
                      alt="..."
                    />
                    <br />
                    {softwareItem.software}
                  </h5>
                  <p className="card-text">
                    <br />
                    <span className="fw-bold">Description</span>
                    <br />
                    <MarkdownHolder>
                      <ReactMarkdown>
                        {softwareItem.description === "" ||
                        softwareItem.description === " " ||
                        softwareItem.description === undefined
                          ? `No description available`
                          : softwareItem.description}
                      </ReactMarkdown>
                    </MarkdownHolder>
                  </p>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    style={{ margin: "5px" }}
                    data-bs-toggle="modal"
                    data-bs-target="#downloadOriAppModal"
                  >
                    Download
                  </button>
                  <p className="card-text">
                    <small className="text-body-secondary">
                      Category: {softwareItem.categories.join(", ")}
                    </small>
                  </p>

                  <p className="card-text">
                    <small className="text-body-secondary">
                      By{" "}
                      {softwareItem.owners.map((value: any, index: any) => {
                        console.log(value.name);
                        if (value.website) {
                          return (
                            <span>
                              {index ? ", " : ""}

                              <a
                                key={`software_${index}`}
                                target="_blank"
                                href={value.website}
                                className="link-secondary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
                                rel="noreferrer"
                              >
                                {value.name}
                              </a>
                            </span>
                          );
                        } else {
                          return (
                            <span key={`software_${index}`}>
                              {(index ? ", " : "") + value.name}
                            </span>
                          );
                        }
                      })}
                    </small>
                  </p>
                </div>
              </div>
              /*<div
                className="col-lg-4 col-md-6"
                style={{
                  background: "grey",
                  width: "40rem",
                  height: "40%",
                  margin: "10px",
                }}
              >
                <div>
                  <div>
                    <img
                      src="/img/cropped-ORI-Logo-300x125-300x125-1-4.png"
                      className="rounded img-fluid"
                      style={{
                        background: "green",
                        width: "15rem",
                        height: "15rem",
                      }}
                    />
                  </div>
                  <div
                    className="product-content text-center py-3"
                    style={{ background: "red" }}
                  >
                    <span
                      className="d-block text-uppercase fw-bold"
                      style={{ background: "blue" }}
                    >
                      {softwareItem.software}
                    </span>
                    <span className="d-block" style={{ background: "yellow" }}>
                      <a href={softwareItem.owner.website}>
                        {softwareItem.owner.name}
                      </a>{" "}
                      - {softwareItem.category}
                    </span>
                  </div>
                </div>
              </div>
                    */
            ))}
        </div>
      </div>
    </motion.header>
  );
}

export default AppDownload;
